import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Markdown from '../components/Markdown';

export default () => {
  const intl = useIntl();
  const file = `cookies-policy.${intl.locale}.md`;
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'Cookies Policy' })} />
      <Markdown text={require(`!!raw-loader!../../data/static/${file}`)} />
    </Layout>
  );
};
